@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://use.fontawesome.com/releases/v5.14.0/css/all.css');

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
}

input,
strong,
p {
  margin: 0;
  padding: 0;
  border: 0;
}

video::-webkit-media-controls {
  display: none !important;
}

video.controls::-webkit-media-controls  {
  display: -webkit-flex !important;
}

@media screen and (max-height: 630px) {
  html,
  body {
    font-size: 80%;
  }
}

.hidden {
  display: none !important;
}


#root {
  height: 100%;
}
